import React, { useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { toast } from "react-toastify";

// Utils
import { capitalizeFirstLetter } from "../../../utils";

// Redux actions
import { getAllcountres } from "../../../redux/adminSlice/countrySlice";
import { getCitiesOfACountry } from "../../../redux/adminSlice/citySlice";

// API call
import { fileUpload } from "../../../services/api/apiHelpers";
import { JSON_API } from "../../../services/api/jsonApiCallWithInterceptor";

import Loader from "../../../Component/Loader";
import Select from "react-select";
import useGet from "../../../Hooks/useGet";
import useCountries from "../../../Hooks/useCountries";
import SelectInput from "../../../Component/SelectInput";

let initVal = {
  countryId: "",
  countryName: "",
  cityId: "",
  cityName: "",
  name: "",
  image: "",
  merchantId: "",
};

let createErrors = {
  countryId: "",
  cityId: "",
  name: "",
  image: "",
};

function AddTourModule() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const [tourModule, settourModule] = useState(initVal);
  const [errors, seterrors] = useState(createErrors);
  const [Img, setImg] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  // Redux stores
  const { countres } = useSelector((state) => state.countrySlice);
  const { cities_of_a_country } = useSelector((state) => state.citySlice);
  const allMerchants = useGet("/admin/get-all-merchant-profile");
  const { cities, fetchCities, loading } = useCountries();
  console.log("allMerchants", allMerchants);

  useEffect(() => {
    dispatch(getAllcountres());
  }, []);
  
  useEffect(() => {
    setisLoading(loading)
  }, [loading]);

  const handleChange = (event) => {
    event.preventDefault();
    let input = capitalizeFirstLetter(event);
    settourModule({ ...tourModule, [event.target.name]: input });
  };

  const handleValidation = () => {
    const { countryId, cityId, name } = tourModule;

    if (countryId === "") {
      seterrors({ ...errors, countryId: "Country is required" });

      return false;
    }
    if (cityId === "") {
      seterrors({ ...errors, cityId: "City is required" });

      return false;
    }
    if (name === "") {
      window.scrollTo(0, 0);
      seterrors({ ...createErrors, name: "Name is required." });

      return false;
    }

    return true;
  };

  const handleSubmit = async (event) => {
    if (isLoading) return;
    if (imgLoading) return;

    if (handleValidation()) {
      if (Img === "") return toast.error("Pictured is required");
      try {
        setisLoading(true);
        let res = await JSON_API["adminAddTourModule"]({
          ...tourModule,
          image: Img,
        });

        if (res.isSuccess) {
          setImg("");
          settourModule(initVal);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          seterrors(createErrors);
          // apiCallSuccess(res.data);
          toast.success("Tour module added successfully!");
        } else {
          toast.error(res?.error?.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setisLoading(false);
    }
  };

  const imageHandler = async (e) => {
    if (e.target.files.length === 0) return;
    if (imgLoading) return;
    const x = Array.from(e.target.files)[0];
    setImg(URL.createObjectURL(x));
    const DATA = new FormData();
    DATA.append("image", e.target.files[0]);
    try {
      setImgLoading(true);
      let imgUpRes = await fileUpload("/admin/tourImage", "POST", DATA);
      if (imgUpRes?.status) {
        setImg(imgUpRes?.url);
      } else {
        toast.error(
          "Something went wrong while uploading image! Please try after some time."
        );
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
      toast.error(
        "Something went wrong while uploading image! Please try after some time."
      );
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div className="card-title">Add Tour Module</div>
            <div className="row" style={{ margin: "0.1rem" }}>
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="countryId"
                    className="form-label font-weight-bold"
                  >
                    Country*
                  </label>
                  <Select
                    name="countryId"
                    id="countryId"
                    classNamePrefix="select"
                    options={countres?.map((item, idx) => ({
                      value: item?._id,
                      label: item?.name,
                    }))}
                    // isMulti
                    value={{
                      value: tourModule.countryId,
                      label: tourModule.countryName,
                    }}
                    onChange={(val) => {
                      settourModule((prev) => {
                        return {
                          ...prev,
                          countryId: val?.value,
                          countryName: val?.label,
                          cityId: "",
                          cityName: "",
                        };
                      });
                      dispatch(getCitiesOfACountry(val?.value));
                    }}
                  />
                </div>
                {errors.countryId && (
                  <span className="errorMessageText text-danger">
                    {errors.countryId}
                  </span>
                )}
              </div> */}
              {/* <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor="cityId"
                    className="form-label font-weight-bold"
                  >
                    City*
                  </label>
                  <Select
                    name="cityId"
                    id="cityId"
                    classNamePrefix="select"
                    options={cities?.map((item, idx) => ({
                      value: item?._id,
                      label: item?.cityName,
                    }))}
                    // isMulti
                    value={{
                      value: tourModule.cityId,
                      label: tourModule.cityName,
                    }}
                    onChange={(val) => {
                      settourModule((prev) => {
                        return {
                          ...prev,
                          cityId: val?.value,
                          cityName: val?.label,
                        };
                      });
                    }}
                  />
                </div>
                {errors.cityId && (
                  <span className="errorMessageText text-danger">
                    {errors.cityId}
                  </span>
                )}
              </div> */}
              <SelectInput
                className="col-md-6"
                label="Merchants"
                options={allMerchants}
                valueKey="_id"
                labelKey={["companyName",  "marchentCode"]}
                value={tourModule.merchantId}
                onChange={(val) => {
                  console.log(val);
                  settourModule((prev) => {
                    return {
                      ...prev,
                      merchantId: val._id,
                      countryId: val.countryId,
                      cityId:""
                    };
                  });
                  if (val.countryId) fetchCities(val.countryId);
                  // dispatch(getCitiesOfACountry(val?.value));
                }}
              />
              <SelectInput
                className="col-md-6"
                id={"cities"}
                label="Cities"
                options={cities}
                valueKey="_id"
                labelKey="cityName"
                value={tourModule.cityId}
                onChange={(val) => {
                  console.log(val);
                  settourModule((prev) => {
                    return {
                      ...prev,
                      cityId: val._id,
                    };
                  });
                  // if (val.countryId) fetchCities(val.countryId);
                  // dispatch(getCitiesOfACountry(val?.value));
                }}
              />

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label font-weight-bold">
                    {/* Module Name* */}
                    Activity type: (E.g : Walking tours of London's Westend)
                  </label>
                  <input
                    name="name"
                    id="name"
                    // placeholder="Your first name, e.g. Walter"
                    type="text"
                    className="form-control"
                    autoComplete="false"
                    value={tourModule.name}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.name && (
                  <span className="errorMessageText text-danger">
                    {errors.name}
                  </span>
                )}
              </div>
              <div className="col-md-6" id="img_input_block">
                <div className="mb-3">
                  <label
                    htmlFor="image"
                    className="form-label font-weight-bold"
                  >
                    Image*
                  </label>
                  <input
                    name="image"
                    id="image"
                    type="file"
                    accept={"image/*"}
                    ref={fileInputRef}
                    className="form-control"
                    onChange={imageHandler}
                  />
                </div>
                <p className="text-primary mt-2">
                  {imgLoading && "Uploading please wait..."}
                </p>
                {Img !== "" && (
                  <>
                    <img
                      alt=""
                      style={{
                        height: "70px",
                        width: "120px",
                        borderRadius: "3px",
                      }}
                      src={Img}
                    ></img>
                  </>
                )}
              </div>
            </div>

            <button
              className="mt-2 btn btn-primary ml-auto d-table"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTourModule;
